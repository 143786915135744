
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include except-mobile {

  .modal {
    width: 400rem;
  }
}
