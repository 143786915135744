
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  min-width: 220rem;
}

.row {

  &:nth-child(even) {

    @include dark-theme {
      background-color: lighten($dark-background-states, 5%);
    }

    @include light-theme {
      background-color: darken($white, 5%);
    }
  }
}

.left {
  width: 63%;
}

.right {
  width: 37%;
}
