
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  position: relative;
  height: 200rem;
  padding-top: 8rem;
  padding-bottom: 22rem;
}

.noStats {
  position: absolute;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);

  @include dark-theme {
    background: $dark-background-states;
  }

  @include light-theme {
    background: $white;
  }
}

.yLegendContainer {
  z-index: 0;
  flex: none;
  width: 20rem;
  height: 100%;
  margin-right: 12rem;
}

.yLegend {
  height: 1px;

  &::before {
    position: absolute;
    right: 0;
    width: calc(100% - 30rem);
    height: 1px;
    content: '';

    @include dark-theme {
      background: rgba($white, .25);
    }

    @include light-theme {
      background: $dark-gray;
    }
  }
}

.xLegend {
  position: absolute;
  bottom: 0;
}

.legendText {
  margin-top: -6rem;
}

.barContainer {
  z-index: 1;
  flex: 1;
  width: 13rem;
  height: 100%;
  margin: 0 2rem;

  &:first-child {
    margin-left: 4rem;
  }

  &:last-child {
    margin-right: 4rem;
  }
}

.bar {
  width: 100%;
  background-image: linear-gradient(0deg, $info-states 0%, $info 100%);
  border-top-left-radius: 8rem;
  border-top-right-radius: 8rem;
  transition: height ease 0.5s;
}

@include mobile {

  .noStats {
    top: calc(50% - 42rem);
  }
}

@include except-mobile {

  .noStats {
    top: calc(50% - 22rem);
  }
}
