$light-background: #f5f6fa !default;
$dark-background: #1e1e24 !default;
$dark-background-states: #27293d !default;
$dark-gray: #9a9a9a !default;
$light-gray: #808080 !default;

$default: #344675 !default;
$primary: #e14eca !default;
$success: #00f2c3 !default;
$green: #92dc88 !default;
$info: #1d8cf8 !default;
$warning: #ff8d72 !default;
$danger: #fd5d93 !default;
$black: #222a42 !default;
$border: #2b3553 !default;

$default-states: #263148 !default;
$primary-states: #ba54f5 !default;
$green-states: #83ca7a !default;
$info-states: #3358f4 !default;
$warning-states: #ff6491 !default;
$danger-states: #ec250d !default;
$black-states: #1d253b !default;

$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #525f7f !default;
$gray-800: #32325d !default;
$gray-900: #212529 !default;
