
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
$areaSise: 20rem;
$mainColor: $info;

.mapContainer {

  img {
    @include dark-theme {
      filter: brightness(.75);
    }

    @include light-theme {
      filter: invert(1) grayscale(1) brightness(0.97);
    }
  }
}

.checkpoint1,
.checkpoint2,
.checkpoint3,
.checkpoint4 {
  position: absolute;
  width: $areaSise;
  height: $areaSise;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background-color: $mainColor;
    border-radius: 50%;
    content: '';
  }

  &::after {
    box-shadow: 0 0 15rem 5rem rgba($mainColor, .5);
  }

  &::before {
    animation: pulse 2s infinite;
  }
}

.position {
  position: relative;
  z-index: 30;
  width: $areaSise;
  height: $areaSise;
}

.checkpoint3 {
  top: 70%;
  left: 90%;
}

.checkpoint4 {
  right: 16%;
  bottom: 41%;
}

@mixin get-sizes($size) {
  width: $size;
  height: $size;
  margin: -#{math.div($size, 2)} 0 0 -#{math.div($size, 2)};
}

@include mobile {

  .checkpoint1,
  .checkpoint2,
  .checkpoint3,
  .checkpoint4 {

    &::before {
      @include get-sizes(6px);
    }
  }

  .checkpoint1 {
    top: 19%;
    left: 12%;
  }

  .checkpoint2 {
    top: 16%;
    left: 50%;
  }
}

@include tablet {

  .checkpoint1,
  .checkpoint2,
  .checkpoint3,
  .checkpoint4 {

    &::before {
      @include get-sizes(8px);
    }
  }
}

@include tablet-portrait {

  .checkpoint1 {
    top: 26%;
    left: 16%;
  }

  .checkpoint2 {
    top: 20%;
    left: 52%;
  }
}

@include tablet-landscape {

  .checkpoint1 {
    top: 24%;
    left: 16%;
  }

  .checkpoint2 {
    top: 18%;
    left: 51%;
  }
}

@include desktop {

  .checkpoint1,
  .checkpoint2,
  .checkpoint3,
  .checkpoint4 {

    &::before {
      @include get-sizes(10px);
    }
  }

  .checkpoint1 {
    top: 25%;
    left: 17%;
  }

  .checkpoint2 {
    top: 17%;
    left: 52%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($mainColor, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20rem rgba($mainColor, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($mainColor, 0);
  }
}
