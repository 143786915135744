// Animation

@mixin animate-show-content($time: .3s) {
  animation: show-content $time ease-in-out;
}

@mixin animate-rotate360($time: .3s) {
  animation: rotate360 $time ease-in-out;
}

@mixin animate-pulse($time: 1.5s) {
  @include light-theme {
    animation: dark-pulse $time infinite;
  }

  @include dark-theme {
    animation: light-pulse $time infinite;
  }
}

@mixin block-loader($size: 30rem, $width: 4rem, $color: white) {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin: -#{math.div($size, 2)} 0 0 -#{math.div($size, 2)};
    border-top: $width solid rgba($color, 0.2);
    border-right: $width solid rgba($color, 0.2);
    border-bottom: $width solid rgba($color, 0.2);
    border-left: $width solid $color;
    border-radius: 50%;
    transform: translateZ(0);
    animation: rotate360 1.1s infinite linear;
    content: '';
    pointer-events: none;
  }
}

@mixin card-loader {
  position: relative;

  @include dark-theme {
    @include block-loader(50rem, 6rem, lighten($dark-background-states, 20%));
  }

  @include light-theme {
    @include block-loader(50rem, 6rem, darken($white, 20%));
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    content: '';

    @include dark-theme {
      background-color: $dark-background-states;
    }

    @include light-theme {
      background-color: $white;
    }
  }

  &::after {
    z-index: 20;
  }
}

// Keyframes

@keyframes show-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dark-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes light-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
