
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include media-tablet-portrait-and-lower {

  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

@include media-tablet-landscape-and-higher {

  .modal {
    width: 592px;
  }

  .fields {
    display: grid;
    grid-template-columns: 200rem auto;
    align-items: center;
  }
}
