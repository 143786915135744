@mixin dark-theme {
  :global(.body-dark-theme) & {
    @content;
  }
}

@mixin light-theme {
  :global(.body-light-theme) & {
    @content;
  }
}

@mixin default-color($property: color, $important: null) {
  #{$property}: $black-states $important;

  :global(.body-dark-theme) & {
    #{$property}: $white $important;
  }
}

@mixin inverse-color($property: color, $important: null) {
  #{$property}: $white $important;

  :global(.body-dark-theme) & {
    #{$property}: $black-states $important;
  }
}

@mixin main-menu-open {
  :global(.main-menu-open) & {
    @content;
  }
}
