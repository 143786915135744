
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.root {
  height: 75rem;
}

.inputContainer {
  width: 100%;
}

.input {
  flex: 1 1 auto;
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.button {
  flex-shrink: 0;
  width: 65rem;
  height: 50rem;
  padding: 15rem 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  div {
    font-size: 14rem;
  }

  &:hover {
    transform: none;
  }
}
