
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.text {
  max-width: 300rem;
  margin: 0 auto;

  svg {
    color: $info;
    font-size: 14rem;
  }
}

.link {
  margin-left: 4px;
}
