
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.loader {
  height: 90rem;
  @include card-loader;
}

@include except-mobile {

  .modal {
    width: 450rem;
  }
}
