
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  width: 100%;
  border-radius: 8rem;
  transition: .2s;

  @include light-theme {
    border: solid 1px rgba(29, 37, 59, .5);
  }

  @include dark-theme {
    border: solid 1px $border;
  }

  &.loading {

    @include dark-theme {
      @include block-loader(20rem, 3rem, lighten($default, 20%));
    }

    @include light-theme {
      @include block-loader(20rem, 3rem, #a3a7b9);
    }

    .text,
    .imageContainer {
      opacity: 0;
    }
  }

  &.selected {
    border: solid 1px $info;

    .selectedTextContainer {
      opacity: 1;
    }
  }
}

.imageContainer {
  margin-top: -1px;
  line-height: 0;
}

.selectedTextContainer {
  position: absolute;
  top: -10rem;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: .2s;
}

.selectedText {
  padding: 0 8rem;

  @include dark-theme {
    background-color: $dark-background-states;
  }

  @include light-theme {
    background-color: $white;
  }
}
