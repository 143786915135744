
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.text a {
  color: $info;

  &:hover {
    color: lighten($info, 10%);
  }
}
