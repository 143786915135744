
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.header {

  @include dark-theme {
    border-bottom: solid 1px $border;
  }

  @include light-theme {
    border-bottom: solid 1px lighten($border, 65%);
  }
}
