
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.item {

  @include dark-theme {
    border: solid 1px lighten($dark-background-states, 5%);
  }

  @include light-theme {
    border: solid 1px darken($white, 3%);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    content: '';

    @include dark-theme {
      background-color: lighten($dark-background-states, 5%);
    }

    @include light-theme {
      background-color: darken($white, 3%);
    }
  }

  & + & {
    margin-top: 16rem;
  }

  &.loading {
    @include card-loader;
  }
}

.closeButton {
  position: absolute;
  top: 8rem;
  right: 8rem;
  z-index: 10;
}

.value {
  transition: .1s;

  &:hover {
    color: $warning;
  }
}
