
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.card {

  @include dark-theme {
    .text {
      color: $dark-gray;
    }

    .content::before {
      background-color: $border;
    }
  }

  @include light-theme {
    .text {
      color: $light-gray;
    }

    .content::before {
      background-color: lighten($border, 65%);
    }
  }

  &.loading {
    @include card-loader;
  }
}

.bottomNode {
  min-width: 60rem;
}

.content {
  min-height: 118rem;

  &::before {
    position: absolute;
    bottom: 0;
    left: 16rem;
    width: calc(100% - 32rem);
    height: 1px;
    content: '';
  }
}

.border-color-red {

  .content::before {
    background-color: transparent !important;
    background-image: radial-gradient(circle, #d63d3e 0%, transparent 100%);
  }
}

.border-color-dark-green {

  .content::before {
    background-color: transparent !important;
    background-image: radial-gradient(circle, #579250 0%, transparent 100%);
  }
}

.border-color-blue {

  .content::before {
    background-color: transparent !important;
    background-image: radial-gradient(circle, $info 0%, transparent 100%);
  }
}

.border-color-green {

  .content::before {
    background-color: transparent !important;
    background-image: radial-gradient(circle, $green 0%, transparent 100%);
  }
}

.border-color-orange {

  .content::before {
    background-color: transparent !important;
    background-image: radial-gradient(circle, $warning 0%, transparent 100%);
  }
}
