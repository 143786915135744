
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.container {
  position: relative;
  width: 100%;

  @include dark-theme {
    background-color: lighten($dark-background-states, 3%);
  }

  @include light-theme {
    background-color: darken($white, 2%);
  }

  &.loading {
    @include card-loader;
  }
}


@include mobile {

  .container {
    min-height: 78rem;
  }
}

@include except-mobile {

  .container {
    min-height: 86rem;
  }
}
