
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.note {
  margin: 36rem 0;
}

.ordersButton {
  margin-left: 24rem;
}

@include mobile {

  .select {
    width: 100%;
    margin-bottom: 16rem;
  }

  .note {
    margin: 24rem 0;
  }

  .ordersButton,
  .submitButton {
    padding: 15rem 32rem;
  }
}

@include except-mobile {

  .control {
    display: flex;
    align-items: flex-start;
  }

  .input {
    flex: 1 1 auto;
    margin-left: 12rem;
  }

  .select {
    width: 150rem;
  }
}
