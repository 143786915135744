
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include mobile {

  .text {
    max-width: 120rem;
  }
}
