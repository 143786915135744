
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include except-mobile {

  .box {
    width: 50%;
  }
}
