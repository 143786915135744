
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.info {
  height: 92rem;
}

.note {
  margin-top: 27rem;
}

.checkboxContainer {
  height: 22rem;
}

.depositInputContainer {
  max-width: 510rem;
}
