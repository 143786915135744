
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include except-mobile {

  .card {
    height: 520rem;
  }
}
