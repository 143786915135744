
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.card {

  @include dark-theme {
    background-color: $dark-background-states;
    box-shadow: 0 1px 20rem 0 rgb(0, 0, 0, 10%);
  }

  @include light-theme {
    background-color: $white;
    box-shadow: 0 1px 15rem 0 rgb(123, 123, 123, 5%);
  }
}
