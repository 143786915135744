
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
@include except-mobile {

  .modal {
    width: 520rem;
  }

  .inputContainer input {
    font-size: 15rem !important;
  }
}
