
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.checkboxContainer {
  min-height: 22rem;
}

.depositInputContainer {
  max-width: 510rem;
}

.stakeInfo {
  height: 92rem;
}
