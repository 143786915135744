
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.tooltip {

  svg {
    color: $info;
    font-size: 16rem;
  }
}
