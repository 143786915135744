
      @use 'sass:math';
      @import '~src/scss/index.scss';
    
.content {
  height: 100%;
}

.slider,
.statistic {
  width: 100%;
}

.slider {
  flex: 1 1 0;
  height: 100%;
}

@include except-mobile {

  .card {
    height: 520rem;
  }
}
